import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { StoriesData } from '../table/data'
import { observer } from 'mobx-react-lite'
import { Story } from 'store/modules/stories'

type StoriesTableContextProps = {
  globalFilter: string
  setGlobalFilter: (value: string) => void
  columnFilters: ColumnFiltersState
  setColumnFilters: (value: ColumnFiltersState) => void
  // Used internally for tracking events
  columnFiltersObject: Record<string, string>
  data: StoriesData
  table: Table<Story>
}

const StoriesTableContext =
  React.createContext<StoriesTableContextProps | null>(null)

export const StoriesTableProvider = observer(
  ({
    children,
  }: {
    children:
      | React.ReactNode
      | ((ctx: StoriesTableContextProps) => React.ReactNode)
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const storiesData = React.useMemo(() => {
      return new StoriesData()
    }, [])

    const table = useReactTable({
      data: storiesData.data,
      columns: storiesData.columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: {
        columnFilters,
        globalFilter,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
    })

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      globalFilter,
      setGlobalFilter,
      table,
      data: storiesData,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <StoriesTableContext.Provider value={contextValue}>
        {content}
      </StoriesTableContext.Provider>
    )
  }
)

export const useStoriesTable = () => {
  const context = React.useContext(StoriesTableContext)
  if (context === null) {
    throw new Error(
      'useStoriesTable must be used within a StoriesTableProvider'
    )
  }
  return context
}
