import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { formatDistanceToNowStrict } from 'date-fns'
import { Story } from 'store/modules/stories'

export const LastEditCell = (data: CellContext<Story, unknown>) => {
  const lastEdit = data.cell.row.original?.updatedAt

  return (
    <div className="flex flex-row justify-start items-center text-gray-600 text-xs">
      {formatDistanceToNowStrict(new Date(lastEdit), {
        unit: 'day',
        roundingMethod: 'ceil',
      })}{' '}
      ago
    </div>
  )
}
