import * as React from 'react'

import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { Win } from 'store/modules/wins'
import { Action } from 'store/modules/actions'
import { FeedbackItem } from 'store/modules/feedback-item'
import { WinItem } from 'components/activity-feed/grid/types/win-item'
import { ActionItem } from 'components/activity-feed/grid/types/action-item'
import { FeedbackItem as FeedbackItemComponent } from 'components/activity-feed/grid/types/feedback-item'
import cn from 'classnames'
import { StoryItem } from 'store/modules/story-items'
import {
  AvatarButtonGroup,
  Banner,
  Button,
  EditableTitle,
  GlobalModal,
  Link,
  useModalContext,
} from 'src/design-system'
import { successToast } from '../../utils/success-toast'
import { errorToast } from '../../utils/error-toast'
import { Editor, EditorContext } from 'components/atoms/editor'
import { AiGenerateButton } from 'components/ai/ai-generate-button'
import { avatarGroupProps } from '../../utils/user-helpers'
import { ArrowsClockwise, Info, Plus, X } from '@phosphor-icons/react'
import { WorkFeed } from 'components/home-page/work-feed'
import { trackEvent } from '../../services/event-tracker'
import { formatDate } from '../../utils/date-helpers'
import { SkillTags } from 'components/skill-tags'

const ADD_TO_STORY_MODAL_ID = 'add-to-story-modal'

export const StoryPage = observer<{ storyId: string }>(({ storyId }) => {
  const story = store.stories.byId(storyId)

  const titlePlaceholder = 'Untitled Story'
  const contentPlaceholder = 'Tell your story here.'

  React.useEffect(() => {
    store.stories.fetchOne(storyId, {
      include: [
        'story_items',
        'story_items.storyable',
        'story_items.storyable.skills',
      ],
    })
    setContent(story?.content || contentPlaceholder)
  }, [storyId, story?.content])

  const editor = React.useRef<EditorContext>(null)

  const [content, setContent] = React.useState('')

  const { openModal } = useModalContext()

  const onTitleUpdate = React.useCallback(
    async (updatedTitle) => {
      if (!story) return

      const title =
        updatedTitle === titlePlaceholder ? story.title : updatedTitle

      if (title === story.title) return

      const res = await store.stories.update(
        story.id,
        {
          title,
        },
        {
          source: 'app-story-page',
        }
      )
      if (res.success) {
        successToast('Title saved')
      } else {
        errorToast(
          'Sorry there was a problem applying your updates, please try again.'
        )
      }
    },
    [story]
  )

  const onDescriptionUpdate = React.useCallback(async () => {
    if (!story) return

    const updatedContent =
      content === contentPlaceholder ? story.content : content

    if (updatedContent === story.content) return

    const res = await store.stories.update(
      story.id,
      {
        content: updatedContent,
      },
      {
        source: 'app-story-page',
      }
    )
    if (res.success) {
      successToast('Changes saved')
    } else {
      errorToast(
        'Sorry there was a problem applying your updates, please try again.'
      )
    }
  }, [content, story])

  if (!story) {
    return null
  }

  const items: StoryItem[] = story.storyItems.sort((a, b) => {
    if (a.aasmState === 'suggested') return -1
    if (b.aasmState === 'approved') return 1
    return 0
  })

  const collaborators = React.useMemo(() => {
    return items
      .flatMap(({ storyable }) => {
        if (Win.is(storyable))
          return [...(storyable.winners ?? []), storyable.reporter]
        if (Action.is(storyable)) return [storyable.user]
        if (FeedbackItem.is(storyable))
          return [storyable.author, storyable.receiver]
        return []
      })
      .filter((user, i, arr) => arr.indexOf(user) === i)
  }, [items])

  const storySkills = React.useMemo(() => {
    return items.flatMap(({ storyable }) => {
      if (!storyable) return []

      return storyable.skills.map((skill) => {
        return {
          id: skill?.id,
          name: skill?.name || '',
          slug: skill?.slug,
        }
      })
    })
  }, [items])

  return (
    <>
      <Banner variant="warning">
        <Info className="mr-1" /> Stories is in alpha testing.{' '}
        <Link
          href="https://airtable.com/appMhlsalaLaWPusR/shrmgrTbt9obrgLXn"
          target="_blank"
        >
          Give feedback
        </Link>
      </Banner>

      <div className="px-28 py-6 grid gap-y-7">
        <div className="flex flex-row justify-between pt-12">
          <div className="flex items-center gap-3.5 text-gray-700">
            <div className="flex items-center">
              <div className="ml-auto mr-1 text-sm">Supporters</div>
              <AvatarButtonGroup
                size="xs"
                className="mr-2"
                avatars={avatarGroupProps(collaborators, {
                  href: true,
                  stateTooltip: true,
                })}
              />
            </div>
            <div className="flex">&middot;</div>
            {store.currentUser && (
              <SkillTags
                focusSkillIds={store.userSkills.focusedSkillIdsForUser(
                  store.currentUser.id
                )}
                showLinks
                skills={storySkills}
                characterLimit={60}
              />
            )}
            <div className="flex">&middot;</div>
            {formatDate(story.createdAt) || '-'}
          </div>
          <div className="flex text-sm gap-3">
            {store.featureFlags.featureEnabled('stories_update_mode') && (
              <Button variant="outline" colourVariant="paper">
                {/* TODO: this will toggle the story between manual and auto mode (i.e. whether suggested story items need approving) */}
                <ArrowsClockwise weight="bold" />
              </Button>
            )}
            <FakeStoryExportButton />
            <Button onClick={() => openModal(ADD_TO_STORY_MODAL_ID)}>
              <Plus /> Add
            </Button>
          </div>
        </div>

        <div className="flex">
          <EditableTitle
            altTitle={`Edit Story`}
            title={story.title ?? titlePlaceholder}
            placeholder={titlePlaceholder}
            headingLevel={1}
            titleClassName={cn('text-title', !story.title && 'text-gray-200')}
            className="text-title mb-3 flex-grow"
            inputClassName="text-title"
            onEdit={onTitleUpdate}
          />
        </div>

        <div className="flex relative mb-20">
          <Editor
            ref={editor}
            name="content"
            initialContent={content}
            className={cn(
              'w-full mb-2 -mx-1.5 pb-12',
              !story.content && 'text-gray-200'
            )}
            rows={4}
            onChange={(value) => setContent(value)}
            onBlur={onDescriptionUpdate}
          />

          <AiGenerateButton
            className="text-pink-600 border-pink-600 mb-4 absolute right-3 bottom-0"
            source="story-page"
            inputs={{
              updates: items.map((item) => {
                let type

                if (Win.is(item.storyable)) type = 'Win'
                if (Action.is(item.storyable)) type = 'Action'
                if (FeedbackItem.is(item.storyable)) type = 'Feedback'

                return {
                  id: item?.storyable?.id,
                  type: type,
                  content: item?.storyable?.content,
                }
              }),
              showWarning: content !== contentPlaceholder,
            }}
            contentType="storySummary"
            onUse={async (output) => {
              const DOC = {
                type: 'doc',
                content: [
                  {
                    type: 'paragraph',
                    content: [
                      {
                        type: 'text',
                        text: output.storySummary?.output?.extracted,
                      },
                    ],
                  },
                ],
              }

              editor.current?.reset(DOC)

              const res = await store.stories.update(
                story.id,
                {
                  content: output.storySummary?.output?.extracted,
                },
                {
                  source: 'app-story-page-ai',
                }
              )
              if (res.success) {
                successToast('Summary applied!')
              } else {
                errorToast(
                  'Sorry there was a problem applying your updates, please try again.'
                )
              }
            }}
            enablePreview={false}
          />
        </div>

        <div
          className="responsive-grid gap-5"
          style={{ '--grid-min-width': '300px' } as React.CSSProperties}
        >
          {items.map(({ id, storyable }) => {
            if (Win.is(storyable))
              return <WinItem record={storyable} key={id} storyItemId={id} />
            if (Action.is(storyable))
              return <ActionItem record={storyable} key={id} storyItemId={id} />
            if (FeedbackItem.is(storyable))
              return (
                <FeedbackItemComponent
                  record={storyable}
                  key={id}
                  storyItemId={id}
                />
              )
            return null
          })}
        </div>
        <AddToStoryModal storyId={storyId} />
      </div>
    </>
  )
})

export const AddToStoryModal: React.VFC<{ storyId: string }> = ({
  storyId,
}) => {
  const currentUser = store.currentUser

  if (!currentUser) return null

  return (
    <GlobalModal.Root
      id={ADD_TO_STORY_MODAL_ID}
      title="Invite team members"
      className="w-full"
    >
      <div className="flex flex-row h-5 items-center relative md:absolute md:right-6 md:top-6">
        <GlobalModal.CloseButton className="rounded-sm !outline-none focus:border focus:border-solid focus:!border-theme-60 absolute right-2 top-2 md:static">
          <X weight="bold" className="text-gray-900 w-4 h-4" />
          <span className="sr-only">Close</span>
        </GlobalModal.CloseButton>
      </div>
      <WorkFeed
        currentUser={currentUser}
        initialLayout="grid"
        initialStoryMode="editing"
        addingToStoryId={storyId}
      />
    </GlobalModal.Root>
  )
}

const FakeStoryExportButton = () => {
  const [clicked, setClicked] = React.useState(false)

  const options = [
    { id: '1', content: 'Download as PDF' },
    { id: '2', content: 'Save to LinkedIn or portfolio' },
    { id: '3', content: 'Save to my check-in' },
    { id: '4', content: 'Send to my manager' },
  ]

  return (
    <GlobalModal.Root
      id="FAKE_DOOR_STORY_EXPORT"
      title="Export your story"
      trigger={<Button variant="outline">Export</Button>}
      onOpenChange={(open) => {
        open && trackEvent('$track_story_export_clicked')
      }}
    >
      <div className="flex p-8 flex-col w-[500px]">
        <h2 className="mb-2">Export story</h2>
        <p className="mb-4">
          Export is coming soon 🚧. Tell us how you&apos;d like to export:
        </p>
        {clicked ? (
          <p className="font-bold">Thank you!</p>
        ) : (
          <div className="flex gap-2 w-full flex-wrap">
            {options.map((option) => (
              <Button
                key={option.id}
                variant="outline"
                onClick={() => {
                  trackEvent('$track_story_export_option_clicked', {
                    exportOption: option.content,
                  })
                  setClicked(true)
                }}
              >
                {option.content}
              </Button>
            ))}
          </div>
        )}
      </div>
    </GlobalModal.Root>
  )
}

{
  /* <Card className="p-5 w-full sm:w-2/6 mb-4 border-pink-600 flex justify-between self-start">
            <h4 className="text-pink-700 mb-4 leading-normal">
              {outlineContent
                ? 'Here are some key highlights to talk about:'
                : 'Need inspiration?'}
            </h4>
            {outlineContent && (
              <div className="flex flex-col gap-2">
                <ul className="pl-2 text-pink-700">
                  {outlineContent.split('\n').map((item, index) => (
                    <li key={index} className="pb-1">
                      {item.replace(/-/, '')}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <AiGenerateButton
              className="text-pink-600 border-pink-600"
              source="story-page"
              inputs={{
                updates: items.map((item) => {
                  let type

                  if (Win.is(item.storyable)) type = 'Win'
                  if (Action.is(item.storyable)) type = 'Action'
                  if (FeedbackItem.is(item.storyable)) type = 'Feedback'

                  return {
                    id: item?.storyable?.id,
                    type: type,
                    content: item?.storyable?.content,
                  }
                }),
                showWarning: content !== contentPlaceholder,
              }}
              contentType="storyOutline"
              onUse={async (output) => {
                setOutlineContent(output?.storyOutline?.output?.extracted || '')
              }}
              enablePreview={false}
            >
              Summarise updates
            </AiGenerateButton>
          </Card> */
}
