import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Link } from 'src/design-system'
import cn from 'classnames'
import { Story } from 'store/modules/stories'

export const TitleCell = (data: CellContext<Story, unknown>) => {
  const story = data.row.original

  return (
    <div className={cn('flex flex-row gap-x-3 items-center min-w-[200px]')}>
      <div className="flex flex-col">
        <Link
          className="p-0 text-gray-900 font-bold text-sm leading-5 no-underline"
          href={`/stories/${story.id}`}
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          {story.title || '-'}
        </Link>
      </div>
    </div>
  )
}
