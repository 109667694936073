import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Story } from 'store/modules/stories'
import { Tag } from 'src/design-system'
import { ArrowsClockwise, Hand } from '@phosphor-icons/react'

export const StatusCell = (data: CellContext<Story, unknown>) => {
  const story = data.row.original

  return (
    <div className="flex flex-row gap-x-3 items-center">
      {story.addingUpdatesStatus == 'auto' && (
        <Tag variant="success" className="text-xs">
          <ArrowsClockwise className="w-3 h-3 mr-1" /> Auto
        </Tag>
      )}
      {story.addingUpdatesStatus == 'manual' && (
        <Tag variant="info" className="text-xs">
          <Hand className="w-3 h-3 mr-1" /> Manual
        </Tag>
      )}
    </div>
  )
}
