import * as React from 'react'
import { store } from 'store/index'
import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { LoadingContent } from 'components/loading-content'
import { StoriesTableProvider } from './context/stories-table'
import { StoriesTable } from './table/table'
import { CreateFormModalButton } from 'components/create-form-modal'
import * as SecondaryNav from 'components/secondary-nav'

export const StoriesPage = observer(() => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchStories = async () => {
      await store.stories.fetchAll({
        include: ['story_items', 'story_items.storyable'],
        page: { size: 999 },
      })
    }
    fetchStories()
    setLoading(false)
  }, [])

  return (
    <LoadingContent loading={loading}>
      <StoriesTableProvider>
        {({ columnFiltersObject }) => (
          <>
            <SecondaryNav.Root>
              <SecondaryNav.Header className="flex flex-row items-center justify-between w-full">
                Stories
                {store.currentUser && (
                  <CreateFormModalButton
                    title="Add story"
                    user={store.currentUser}
                    label={'New story'}
                    tabs={['story']}
                    source={'stories-index'}
                    colourVariant="theme"
                    variant="default"
                  />
                )}
              </SecondaryNav.Header>
            </SecondaryNav.Root>
            <main className="pt-10 overflow-x-auto flex-1 mb-10">
              {store.currentUser?.isAdmin && (
                <div className="px-10 pb-4">
                  <StoriesAlphaBanner />
                </div>
              )}
              <StoriesTable />
            </main>
          </>
        )}
      </StoriesTableProvider>
    </LoadingContent>
  )
})

const StoriesAlphaBanner = () => (
  <div className="bg-blue-100 p-4 sm:p-6 rounded-lg w-full mb-8">
    <h2 className="mb-2 text-3xl">Build stories of your growth</h2>
    <p>
      Summarise your activity into journeys you want to tell. Build a narrative
      about your achievements, actions and feedback. Share and save it to
      advocate for your growth.
    </p>

    <p className="mb-6">
      This is an alpha experience which is active development. Your thoughts and
      feedback will help us shape the future of it.{' '}
    </p>

    <div className="flex">
      <Button
        variant="outline"
        className="text-blue-700 border-blue-700"
        as="a"
        data-turbo-action="advance"
        href="https://airtable.com/appMhlsalaLaWPusR/shrmgrTbt9obrgLXn"
        target="_blank"
      >
        Give feedback
      </Button>
    </div>
  </div>
)
