import { createColumnHelper } from '@tanstack/react-table'
import {
  StatusCell,
  CreatedCell,
  LastEditCell,
  BlocksCell,
  TitleCell,
  ActionsCell,
} from './cells'

import { store } from 'store/index'
import { Story } from 'store/modules/stories'
export class StoriesData {
  get data(): Story[] {
    return [
      ...store.stories.all.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)),
    ]
  }

  columnHelper = createColumnHelper<Story>()

  get columns() {
    return [
      this.columnHelper.accessor('title', {
        header: 'Title',
        cell: TitleCell,
        id: 'title',
      }),
      this.columnHelper.display({
        header: 'Status',
        cell: StatusCell,
        id: 'status',
      }),
      this.columnHelper.display({
        header: 'Blocks',
        cell: BlocksCell,
        id: 'blocks',
      }),
      this.columnHelper.accessor('createdAt', {
        header: 'Created',
        cell: CreatedCell,
        id: 'createdAt',
      }),
      this.columnHelper.accessor('updatedAt', {
        header: 'Last Edited',
        cell: LastEditCell,
        id: 'lastEdited',
      }),
      this.columnHelper.display({
        cell: ActionsCell,
        id: 'actions',
      }),
    ]
  }
}
