import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { Story } from 'store/modules/stories'

export const ActionsCell = (data: CellContext<Story, unknown>) => {
  const story = data.cell.row.original

  const onDestroy = React.useCallback(async () => {
    const result = await store.stories.byId(story.id)?.destroy()

    if (result?.success) {
      successToast('Story deleted!')
    } else {
      errorToast()
    }
  }, [story])

  return (
    <ConfirmationDialog.Root
      onConfirm={onDestroy}
      title="Are you sure?"
      body="This cannot be undone."
    >
      <div className="flex flex-col">
        <OverflowMenu.Root right>
          <OverflowMenu.Link
            href={`/stories/${story.id}`}
            className="flex flex-row items-center gap-x-1 text-gray-900"
          >
            Edit
          </OverflowMenu.Link>

          <ConfirmationDialog.Trigger asChild>
            <OverflowMenu.Button variant="destructive">
              <div className="flex flex-row items-center gap-x-1 text-red-600">
                Delete
              </div>
            </OverflowMenu.Button>
          </ConfirmationDialog.Trigger>
        </OverflowMenu.Root>
      </div>
    </ConfirmationDialog.Root>
  )
}
