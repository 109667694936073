import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Story } from 'store/modules/stories'
import { Layout } from '@phosphor-icons/react'

export const BlocksCell = (data: CellContext<Story, unknown>) => {
  const story = data.cell.row.original

  return (
    <div className="flex items-center justify-start text-xs">
      <Layout className="mr-1 w-4 h-4 text-gray-600" />{' '}
      {story.storyItems.length}
    </div>
  )
}
